import request from '@/utils/request'

export function list(params) {
  return request({
    url: '/comment/list',
    method: 'get',
    params
  })
}

export function page(params) {
  return request({
    url: '/user/comment/page',
    method: 'get',
    params
  })
}

export function add(data) {
  return request({
    url: '/user/comment',
    method: 'post',
    data
  })
}
