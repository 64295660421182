import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Layout from '@/layout'
import { baiduStatisticsUrl, siteTitle } from '@/config/setting.config'

NProgress.configure({ showSpinner: false })

const routes = [
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/index')
      },
      {
        path: '/subject',
        name: 'Subject',
        component: () => import('@/views/subject')
      },
      {
        path: '/category',
        name: 'Category',
        component: () => import('@/views/category')
      },
      {
        path: '/tag',
        name: 'Tag',
        component: () => import('@/views/tag')
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/about')
      },
      {
        path: '/board',
        name: 'Board',
        component: () => import('@/views/board')
      },
      {
        path: '/archive',
        name: 'Archive',
        component: () => import('@/views/archive')
      },
      {
        path: '/course',
        name: 'Course',
        component: () => import('@/views/course')
      },
      {
        path: '/search/:title',
        name: 'Search',
        component: () => import('@/views/search')
      },
      {
        path: '/category/:categoryName',
        name: 'SearchCategory',
        component: () => import('@/views/category/search')
      },
      {
        path: '/tag/:tagName',
        name: 'SearchTag',
        component: () => import('@/views/tag/search')
      },
      {
        path: '/author/:author',
        name: 'SearchAuthor',
        component: () => import('@/views/author')
      },
      {
        path: '/flag/:flag',
        name: 'SearchFlag',
        component: () => import('@/views/flag')
      },
      {
        path: '/article/:id',
        name: 'Article',
        component: () => import('@/views/article')
      },
      {
        path: '/subject/:id',
        name: 'SubjectArticle',
        component: () => import('@/views/subject/article')
      },
      {
        path: '/course/:id',
        name: 'CourseLecture',
        component: () => import('@/views/course/lecture')
      },
      {
        path: '/lecture/:id',
        name: 'CourseLectureDetail',
        component: () => import('@/views/course/lecture-detail')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  document.title = siteTitle
  next()
  NProgress.done()
})

router.afterEach(() => {
  NProgress.done()
  // 判断百度统计的链接地址是否为空，或者不是生产环境，不需要统计
  if (!baiduStatisticsUrl || process.env.NODE_ENV !== 'production') {
    NProgress.done()
    return
  }

  try {
    setTimeout(() => {
      // 每次执行前，先移除上次插入的代码
      document.getElementById('baidu_tj') &&
        document.getElementById('baidu_tj').remove()
      const hm = document.createElement('script')
      hm.src = baiduStatisticsUrl
      hm.id = 'baidu_tj'
      const s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    }, 0)
  } catch (e) {
    console.log(e)
  }
  NProgress.done()
})

export default router
