import { getToken, setToken, removeToken } from '@/utils/auth'
import { login, logout } from '@/api/auth/login'
import { getUserInfo } from '@/api/auth/user'
import { encrypt } from '@/utils/jsencrypt'

const getters = {
  token: (state) => state.token,
  avatar: (state) => state.avatar,
  isLogin: (state) => state.token !== undefined && state.token !== ''
}

const state = {
  token: getToken(),
  avatar: ''
}

const mutations = {
  setToken: (state, token) => {
    state.token = token
  },
  setAvatar: (state, avatar) => {
    state.avatar = avatar
  }
}

const actions = {
// 登录
  login({ commit }, userInfo) {
    const { username, password, uuid, code } = userInfo
    return new Promise((resolve, reject) => {
      login({
        username: encrypt(username),
        password: encrypt(password),
        uuid,
        code
      })
        .then((response) => {
          const {
            data: { token }
          } = response
          setToken(token)
          commit('setToken', token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // 获取用户信息
  async getUserInfo({ commit }) {
    const {
      data: { avatar }
    } = await getUserInfo()
    commit('setAvatar', avatar)
  },

  // 退出登录
  async logout({ commit }) {
    try {
      await logout()
    } catch (error) {
      console.log(error)
    }
    commit('setToken', '')
    removeToken()
  }
}

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
