/**
 * 导出通用配置
 */
module.exports = {
  // 标题，此项修改后需要重启项目
  title: '格姗知识圈',
  // 网站标题
  siteTitle: '格姗知识圈-专注于技术分享的博客平台！',
  // 网站加载时显示的内容
  siteLoading: '正在为您加载系统资源，请耐心等待',
  // 网站描述
  siteDescription:
    '格姗知识圈是一个专注于技术分享的博客平台！并且还会分享各种实用工具、实用技巧！',
  // 网站关键字
  siteKeywords:
    '格姗知识圈,博客,格姗知识圈博客,格姗知识圈社区,格姗知识圈技术社区,格姗知识圈,IT社区,IT社区,技术社区,Java技术分享,Spring教程,开发者社区',
  // 备案号
  recordTitle: '桂ICP备20004701号',
  // 百度统计代码链接
  baiduStatisticsUrl:
    'https://hm.baidu.com/hm.js?f6e3a24b717230fd285f174565b25a70'

}
