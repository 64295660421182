<template>
  <el-dialog
    v-model="visible"
    :width="isMobile ? '100%' : '50%'"
    append-to-body
    center
    :before-close="close"
  >
    <template #header>
      <div class="login-title">登录</div>
    </template>
    <el-form
      class="login-form"
      :class="isMobile ? 'login-form-mobile' : ''"
      :model="loginForm"
      :rules="loginRules"
      ref="loginRef"
    >
      <h3 class="title">{{ title }}</h3>
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          type="text"
          auto-complete="off"
          placeholder="用户名"
        >
          <template #prefix>
            <svg-icon icon-name="user" class="el-input__icon input-icon" />
          </template>
        </el-input>
      </el-form-item>
      <el-tooltip
        v-model:visible="capsTooltip"
        content="大写已开启"
        placement="right"
      >
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            :type="passwordType"
            auto-complete="off"
            placeholder="密码"
            @keyup="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter="handleLogin"
          >
            <template #prefix>
              <svg-icon
                icon-name="password"
                class="el-input__icon input-icon"
              />
            </template>
          </el-input>
          <span class="show-password" @click="handleShowPassword">
            <svg-icon
              :icon-name="passwordType === 'password' ? 'eye' : 'eye-open'"
            />
          </span>
        </el-form-item>
      </el-tooltip>
      <el-form-item prop="code">
        <el-input
          v-model="loginForm.code"
          auto-complete="off"
          placeholder="验证码"
          class="code-input"
          @keyup.enter="handleLogin"
        >
          <template #prefix>
            <svg-icon icon-name="validCode" class="el-input__icon input-icon" />
          </template>
        </el-input>
        <div class="login-code" v-loading="loadingCodeImage">
          <img :src="codeImageUrl" @click="getCode" class="login-code-img" />
        </div>
      </el-form-item>
      <el-form-item style="width: 100%">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          class="login-button"
          @click.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form-item>
    </el-form>
    <div
      class="login-content"
      v-loading="loading"
      :element-loading-text="loadingText"
    >
      第三方账号登录：
      <el-tooltip
        v-for="config in dataList"
        :key="config.id"
        :content="config.thirdName"
        placement="bottom"
      >
        <!-- <el-button type="primary" round @click="handleThirdLogin(config.source)">{{
          config.source
        }}</el-button> -->
        <svg-icon
          :icon-name="getLoginIcon(config.source)"
          @click="handleThirdLogin(config.source)"
          class="third-icon"
        />
      </el-tooltip>
    </div>
  </el-dialog>
</template>
<script setup>
import { getCurrentInstance, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { list } from '@/api/blog/config'
import { getCaptchaImage, getLoginUrl } from '@/api/auth/login'
import { setLoginRedirectPath } from '@/utils/auth'

const store = useStore()
const route = useRoute()

const { proxy } = getCurrentInstance()

const visible = ref(false)

const isMobile = store.getters['app/isMobile']
const loading = ref(true)
const loadingText = ref('')
const dataList = ref([])
const loginForm = ref({
  username: '',
  password: '',
  code: '',
  uuid: ''
})

/**
 * 显示弹窗
 */
async function show() {
  visible.value = true
  getDataList()
  getCode()
}

/**
 * 获取登录配置
 */
async function getDataList() {
  loading.value = true
  const { data } = await list()
  dataList.value = data
  loading.value = false
}

/**
 * 关闭
 */
function close() {
  visible.value = false
}

// 大写提示
const capsTooltip = ref(false)
/**
 * 检测是否为大写
 */
function checkCapslock(e) {
  const { key } = e
  capsTooltip.value = key && key.length === 1 && key >= 'A' && key <= 'Z'
}

// 密码类型
const passwordType = ref('password')
/**
 * 是否显示密码
 */
function handleShowPassword() {
  passwordType.value = passwordType.value === 'password' ? 'text' : 'password'
}

// 验证规则
const loginRules = ref({
  username: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
  password: [
    { required: true, trigger: 'blur', validator: validatePassword() }
  ],
  code: [{ required: true, trigger: 'blur', message: '验证码不能为空' }]
})
// 密码验证规则
function validatePassword() {
  return (rule, value, callback) => {
    if (!value) {
      callback(new Error('密码不能为空'))
    } else if (value.length < 6) {
      callback(new Error('密码不能少于6位'))
    } else {
      callback()
    }
  }
}

/**
 * 登录
 */
function handleLogin() {
  proxy.$refs.loginRef.validate((valid) => {
    if (!valid) {
      return
    }
    loading.value = true
    // 调用action的登录方法
    store
      .dispatch('user/login', loginForm.value)
      .then(() => {
        window.location.href = route.fullPath
      })
      .catch(() => {
        // 重新获取验证码
        getCode()
      })
      .finally(() => {
        loading.value = false
      })
  })
}

/**
 * 登录
 */
async function handleThirdLogin(source) {
  loading.value = true
  loadingText.value = '正在登录跳转中，请稍等...'
  const {
    data: { url }
  } = await getLoginUrl(source)
  // 记录跳转地址，后续做跳转
  setLoginRedirectPath(route.fullPath)
  window.location.href = url
}

/**
 * 获取图标名称
 */
function getLoginIcon(source) {
  if (source.toLowerCase() === 'qq') {
    return 'qq'
  }
  if (source.toLowerCase() === 'gitee') {
    return 'gitee'
  }
  if (source.toLowerCase() === 'github') {
    return 'github'
  }
}

// 验证码路径
const codeImageUrl = ref('')
// 验证码路径
const loadingCodeImage = ref(true)
/**
 * 获取验证码
 */
async function getCode() {
  loadingCodeImage.value = true
  try {
    const {
      data: { uuid, image }
    } = await getCaptchaImage()
    loginForm.value.uuid = uuid
    codeImageUrl.value = image
  } finally {
    loadingCodeImage.value = false
  }
}

defineExpose({
  show
})
</script>
<style lang="scss" scoped>
.login-title {
  text-align: center;
  font-size: 23px;
  font-weight: bold;
  color: #555;
  border-bottom: 1px solid #dcdfe6;
  padding-bottom: 10px;
}

.login-content {
  width: 100%;
  text-align: center;

  .third-icon {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .icon {
    font-size: 30px;
    cursor: pointer;
    margin: auto 5px;
  }
}

.login-form-mobile {
  width: 100% !important;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  text-align: center;
  margin: 0 auto;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }
  .show-password {
    height: 39px;
    width: 14px;
    position: absolute;
    right: 10px;
    font-size: 16px;
    color: #889aa4;
    cursor: pointer;
    user-select: none;
  }
  .code-input {
    width: 63%;
  }
  .login-code {
    width: 37%;
    height: 38px;
    float: right;
    text-align: center;
    .login-code-img {
      cursor: pointer;
      vertical-align: middle;
    }
  }
  .login-button {
    width: 100%;
  }

  .third-login {
    margin-bottom: 10px;
    font-size: 15px;

    .third-icon {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
</style>
